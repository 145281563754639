* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #828286;
  --main-text-color: #333333;
}

.row {
  margin: 0;
}

html {
  font-size: 100%;
}

a {
  text-decoration: none;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5 {
  color: #333333;
}

table,
th,
td {
  border: 0px solid black;
  border-collapse: collapse;
  padding: 0.15rem;
}

table.center {
  margin-left: auto;
  margin-right: auto;
}

.navbar-bg {
  min-height: 15vh;
  background-color: #000;
  padding: 0 0 0 0;
  z-index: 9;
  position: relative;
}

.navbar-brand {
  text-decoration: none;
  font-weight: bolder;
  font-size: 1.5rem;
}

.navbar li {
  padding: 0 0;
}

.navbar li a {
  font-weight: normal;
  /*font-size: 1.7rem;*/
  position: relative;
}

.navbar li a:hover {
  color: var(--primary -color) !important;
  text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
}

.btn-style {
  width: 6rem;
  height: 3rem;
  border-radius: 1rem;
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
  /*font-size: 1.6rem;*/
}

.btn-style:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(1px);
}

.fa-sun {
  transition: all linear;
  animation: rot 2s linear infinite;
  color: yellow;
}
.btn-style:first-child {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.btn-style:hover {
  border: 0.1rem solid var(--primary-color);
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 50vh;
  border-radius: 20px;
  box-shadow: 0 0 15px 5px grey;
  background: white;
  position: relative;
}

.homepage > .button {
  background: #1877f2;
  border: 1px solid #1877f2;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(1px);
}

.loginregister {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*width: 50vw;
  height: 50vh;*/
  border-radius: 20px;
  box-shadow: 0 0 2.5px 2.5px #808080;
  background: #ffffff;
  position: relative;
}

.loginregister > .button {
  background: lightskyblue;
  border: 1px solid #18f24b;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}

.register {
  width: 400px;
  max-height: 380px;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  text-align: center;
}

.register > input {
  border-radius: 8px;
  border: 2px solid #dddfe2;
  outline: none;
  color: #1d2129;
  margin: 0.5rem 0.75rem;
  width: 92%;
  font-size: 1rem;
}

.register > .button {
  background: rgb(164, 161, 160);
  border-radius: 8px;
  border: 1px solid #18f24b;
  outline: none;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.25rem;
  margin: 0.25rem 0;
  cursor: pointer;
}

.login {
  width: 400px;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  text-align: center;
}

.login > input {
  border-radius: 8px;
  border: 2px solid #dddfe2;
  outline: none;
  color: #1d2129;
  margin: 0.5rem 0.75rem;
  width: 92%;
  font-size: 1rem;
}

.login > .button {
  background: rgb(164, 161, 160);
  border-radius: 8px;
  border: 1px solid #18f24b;
  outline: none;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

header {
  background-color: var(--bgcolor);
  min-height: 80vh;
  width: 100%;
  padding-top: 1.5rem;
}

.common-section .container {
  /*border: 1px solid;*/

  border: 0.1rem solid var(--primary-color);
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem;
  border-radius: 1rem;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main-hero-container {
  min-height: 70vh;
  /* display: flex;*/
  /*background-color: aqua;*/
}

.main-hero-container h3 {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: bolder;
}

.main-hero-para {
  font-size: 1rem;
  color: var(--text-color);
  margin: 0.5rem 0 0.5rem 0;
  font-weight: lighter;
  width: 99%;
  text-justify: center;
  text-align: justify;
}

.darkBold {
  font-weight: bolder;
}

.main-hero-container h3 {
  font-weight: lighter;
}

.span-tag {
  font-weight: bold;
  color: black;
  font-size: 1rem;
  text-decoration-thickness: 1;
}

.span-tag-big {
  font-weight: bold;
  color: black;
  font-size: 1.25rem;
  text-decoration-thickness: 1.25;
}

.input-group {
  position: relative !important;
}

/*.form-control-text {
  position: absolute;
  height: 5rem;
  border: none;
  outline-style: none;
  padding: 1.5rem !important;
  font-size: 1.4rem;
  font-weight: lighter;
}*/

.input-group-button {
  position: absolute;
  top: 1rem;
  right: 50%;
  color: #fff;
  background-color: var(--primary-color);
  height: 4rem;
  width: 15%;
  border-radius: 2rem !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /*font-size: 1.3rem;*/
}

.input-group-button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(1px);
}

.btn-style-header {
  width: 12rem;
  height: 3rem;
  border-radius: 1rem;
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
  /*font-size: 1.6rem;*/
}

.btn-style-header:first-child {
  margin-right: 5rem;
}

.btn-style-header:hover {
  border: 0.1rem solid var(--primary-color);
}

.main-herosection-images {
  position: relative;
}

.main-herosection-images img {
  width: 30%;
  height: 30%;
  margin-top: 10rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  position: absolute;
}

.main-herosection-images .main-hero-img2 {
  position: absolute;
  width: 30%;
  height: 30%;
  left: 35%;
  top: -2%;
  box-shadow: 0.8rem 0 0.8rem rgba(0, 0, 0, 0.25);
}

footer {
  min-height: 15vh;
  padding: 0.3rem 0;
  background-color: #000;
  color: #fff;
  margin-top: auto;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}
footer h4 {
  color: #fff;
}

footer li {
  list-style: none;
  font-size: 0.75rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 0.5rem 0;
}
footer .fontawesome-style {
  font-size: 1rem;
  color: var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 0.1rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
  font-size: xx-small;
}

.common-section {
  margin: 1rem 0;
}
.our-services img {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin-top: 0rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.8rem 0.8rem;
  border: 0.1rem solid var(--primary-color);
  display: block;
  margin: auto;
  /*align-items: center;
  justify-content: center;*/
}
/*
.our-services .mini-title {
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}*/

.our-services .main-heading {
  font-weight: bolder;
}

.our-services-info {
  margin-top: 0.1rem;
}

/*
.our-services .our-services-number {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid var(--primary-color);
  font-size: 1.5rem;
  color: var(--primary-color);
  display: grid;
  place-items: center;
}

.our-services-data {
  padding-left: 2rem;
}*/

.our-services-data {
  padding-left: 0rem;
}

.our-services .our-services-data p {
  margin: 0rem 0;
}

/* --------------------------------- Services Section starts  
                        -------------------------------------------------------*/

.service-main-container {
  margin-top: 0.5rem;
  padding: 0.1rem 0;
}

.service-main-container .main-heading {
  margin: 0.5rem 0;
}

.work-container-subdiv {
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
}

.work-container-subdiv:hover {
  background-color: var(--bgcolor);
  cursor: pointer;
}

.work-container-subdiv:hover > .fontawesome-style {
  background-color: #fff;
}

.work-container-subdiv .main-hero-para {
  width: 100%;
}

.service-main-container .work-container-subdiv .fontawesome-style {
  text-align: left;
  margin: 0.5rem 0;
}

@media (min-width: 1400px) {
  .service-container .col-xxl-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .service-container .col-lg-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: 2.5rem auto;
  }
}

/* --------------------------------- Services Section ends  
                        -------------------------------------------------------*/
.contactus-section {
  margin: 1.5rem 0;
}

.contact-leftside img {
  min-width: 50%;
  max-height: 30rem;
  height: auto;
}

.contact-input-field {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
.contact-rightside form .form-control {
  border: 1px solid #6f5de7;
  /*-webkit-appearance: none; */
  height: 2rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.form-checkbox-style {
  margin: 1rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 0.75rem;
  color: var(--text-color);
  margin: 0;
  font-weight: lighter;
  width: 90%;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

#notfound {
  position: relative;
  height: 80vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Maven Pro", sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: "Maven Pro", sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }

  .loginregister {
    width: auto;
  }

  .login {
    width: auto;
  }
}
@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px !important;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}
